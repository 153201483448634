import "./PaymentPageView.scss";
import { Row } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { paymentPageDetail, paymentPageInitResponse } from "../../store/store";
import { Navigate, useParams } from "react-router-dom";
import { getPageDetailApi } from "../../api/paymentPage";
import PaymentPageLoaded from "./PaymentPageLoaded";
import PaymentPageLoading from "./PaymentPageLoading";
import { useQuery } from "react-query";
import { IPaymentPageDetail } from "@app-models";
import { useEffect } from "react";
import { MESAGE_TYPE } from "../../common/constants";

const PaymentPageView = () => {
  const [, setPageDetail] = useRecoilState(paymentPageDetail);
  const params = useParams<{ code: string | undefined }>();

  const [pageInitValue, setPageInitResponse] = useRecoilState(
    paymentPageInitResponse
  );

  const { isLoading, data, error } = useQuery({
    queryKey: ["payment-page-detial", params.code],
    queryFn: async () => {
      const response = await getPageDetailApi(params.code);

      return response.data as IPaymentPageDetail;
    },
    retry: 1,
  });

  const handleIframeLoad = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("message", (e) => {
      const messagePayload = e.data as {
        type: MESAGE_TYPE;
        data: IPaymentPageDetail;
      };

      if (messagePayload.type === MESAGE_TYPE.SUCCESS) {
        setPageInitResponse(null);
        window.location.href = messagePayload.data.redirectUrl;
      }
    });

    return () => {
      window.removeEventListener("message", (e) => {});
    };
  }, []);

  useEffect(() => {
    if (data) setPageDetail(data);
  }, [data]);

  useEffect(() => {
    return () => {
      setPageInitResponse(null);
    };
  }, []);

  if (isLoading)
    return (
      <section className="payment-page-container">
        <main className="main">
          <PaymentPageLoading />
        </main>
        <footer className="footer">
          <Row className="mb-5">
            <img
              src="https://app.monnify.com/images/logo-white.svg"
              alt="monnify"
            />
          </Row>
          <Row className="ml-5" align={"middle"}>
            <span className="by-text">by</span>
            <img
              src={"https://app.monnify.com/images/moniepointLogo-white.svg"}
              alt="monnify"
            />
          </Row>
        </footer>
      </section>
    );

  if (error) return <Navigate to={"/page/not-found"} />;

  return (
    <section
      style={{ position: "relative" }}
      className="payment-page-container"
    >
      <main className="main">
        <PaymentPageLoaded />
      </main>
      <footer className="footer">
        <Row className="mb-5">
          <img
            src="https://app.monnify.com/images/logo-white.svg"
            alt="monnify"
          />
        </Row>
        <Row className="ml-5" align={"middle"}>
          <span className="by-text">by</span>
          <img
            src={"https://app.monnify.com/images/moniepointLogo-white.svg"}
            alt="monnify"
          />
        </Row>
      </footer>
      {pageInitValue && (
        <div className={`frame-container`}>
          <iframe
            title="Monnify checkout"
            src={pageInitValue?.checkoutUrl}
            width={"100%"}
            height={"100%"}
            onLoad={handleIframeLoad}
            id="frame-container"
            data-testid="iframe-container"
          >
            Unable to load payment methods
          </iframe>
        </div>
      )}
    </section>
  );
};

export default PaymentPageView;
