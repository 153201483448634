import { IPaymentPageDetail, IPaymentPageInitResponse } from "@app-models";
import { atom } from "recoil";
import implementPersist from "./implement-persist";

export const paymentPageDetail = atom<IPaymentPageDetail | null>({
  key: "paymentPageDetail",
  default: null,
  effects_UNSTABLE: implementPersist("paymentPageDetailKey"),
});

export const paymentPageInitResponse = atom<IPaymentPageInitResponse | null>({
  key: "paymentPageInitResponse",
  default: null,
});

export const amountOptionSelected = atom<string | null>({
  key: "amountOptionKey",
  default: null,
});

export const dynamicAmountValue = atom<string | null>({
  key: "dynamicAmountValueKey",
  default: "0",
});

export const paymentPageInitResponseQueryData =
  atom<IPaymentPageInitResponse | null>({
    key: "paymentPageInitResponseQueryData",
    default: null,
    effects_UNSTABLE: implementPersist("paymentPageInitResponseQueryDataKey"),
  });
