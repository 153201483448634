import Axios from "axios";

class TransactionService {
  static controller = new AbortController();

  static queryTransactionStatus = (
    reference: string,
    apiKey: string,
    apiUrl: string
  ) => {
    let url = `${apiUrl}/api/v1/sdk/transactions/query/${apiKey}?transactionReference=${encodeURIComponent(
      reference
    )}`;
    return Axios.get(url, { signal: TransactionService.controller.signal });
  };
}

export default TransactionService;
