import _ from "lodash";
export const TRANSACTION_STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  TIMEOUT: "TIMEOUT",
  EXPIRED: "EXPIRED",
  PAID: "PAID",
  IN_PROGRESS: "IN_PROGRESS",
  PARTIALLY_PAID: "PARTIALLY_PAID",
  PENDING: "PENDING",
  USER_CANCELLED: "USER_CANCELLED",
  CANCELLED: "CANCELLED",
  OVERPAID: "OVERPAID",
  PARTIALLY_REFUNDED: "PARTIALLY_REFUNDED",
  REFUNDED: "REFUNDED",
  UNKNOWN: "UNKNOWN",
  BANK_AUTHORIZATION_REQUIRED: "BANK_AUTHORIZATION_REQUIRED",
  MASTERCARD_AUTHORIZATION_REQUIRED: "MPGS_3DS_AUTHORIZATION_REQUIRED",
  OTP_AUTHORIZATION_REQUIRED: "OTP_AUTHORIZATION_REQUIRED",
  TRANSACTION_PENINDG: "Transaction is Pending",
  TRANSACTION_ALREADY_COMPLETED: "transaction already completed",
};

export const RESPONSE_CODE = {
  TRANSACTION_FAILED: "99",
  TRANSACTION_COMPLETED: "100",
};

export const DISABLED_PAYMENT_METHODS = ["DIRECT_DEBIT", "CASH", "NQR"];

export function isTransactionSuccessful(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  let successResponses = [
    TRANSACTION_STATUS.SUCCESS,
    TRANSACTION_STATUS.PAID,
    TRANSACTION_STATUS.OVERPAID,
    TRANSACTION_STATUS.PARTIALLY_PAID,
    TRANSACTION_STATUS.PARTIALLY_REFUNDED,
    TRANSACTION_STATUS.REFUNDED,
  ];

  paymentStatus = paymentStatus.toUpperCase();
  if (successResponses.indexOf(paymentStatus) > -1) {
    return true;
  }

  return false;
}

export function isTransactionPending(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  let pendingResponse = [
    TRANSACTION_STATUS.PENDING,
    TRANSACTION_STATUS.PARTIALLY_PAID,
  ];
  paymentStatus = paymentStatus.toUpperCase();

  if (pendingResponse.indexOf(paymentStatus) > -1) {
    return true;
  }

  return false;
}

export function isTransactionCancelled(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  let pendingResponse = [
    TRANSACTION_STATUS.CANCELLED,
    TRANSACTION_STATUS.USER_CANCELLED,
  ];
  paymentStatus = paymentStatus.toUpperCase();

  if (pendingResponse.indexOf(paymentStatus) > -1) {
    return true;
  }

  return false;
}

export function isTransactionExpired(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  let responses = [
    TRANSACTION_STATUS.PENDING,
    TRANSACTION_STATUS.EXPIRED,
    TRANSACTION_STATUS.TIMEOUT,
  ];
  paymentStatus = paymentStatus.toUpperCase();

  if (responses.indexOf(paymentStatus) > -1) {
    return true;
  }

  return false;
}

export function isTransactionFailed(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  let responses = [
    TRANSACTION_STATUS.FAILED,
    TRANSACTION_STATUS.USER_CANCELLED,
  ];
  paymentStatus = paymentStatus.toUpperCase();

  if (responses.indexOf(paymentStatus) > -1) {
    return true;
  }

  return false;
}

export function isOtpAuthorizationRequired(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  return paymentStatus === TRANSACTION_STATUS.OTP_AUTHORIZATION_REQUIRED;
}

export function isBankAuthorizationRequired(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  return paymentStatus === TRANSACTION_STATUS.BANK_AUTHORIZATION_REQUIRED;
}

export function isMastercardAuthorizationRequired(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  return paymentStatus === TRANSACTION_STATUS.MASTERCARD_AUTHORIZATION_REQUIRED;
}

export function isTransactionCompleted(paymentStatus: string) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }
  let responses = [
    TRANSACTION_STATUS.USER_CANCELLED,
    TRANSACTION_STATUS.SUCCESS,
    TRANSACTION_STATUS.PAID,
    TRANSACTION_STATUS.OVERPAID,
  ];
  paymentStatus = paymentStatus.toUpperCase();

  if (responses.indexOf(paymentStatus) > -1) {
    return true;
  }

  return false;
}
