import React, { useEffect, useState } from "react";
import { Button, Row, Typography } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { paymentPageDetail, paymentPageInitResponse } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { MESAGE_TYPE } from "../../common/constants";
import MonnifyIcon from "../../common/icons";

const PaymentSuccessful = () => {
  const pageDetail = useRecoilValue(paymentPageDetail);
  const [, setPageDetail] = useRecoilState(paymentPageDetail);
  const [, setPageInitResponse] = useRecoilState(paymentPageInitResponse);
  const [imageLoadError, setImageLoadError] = useState("LOADING");
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageDetail) navigate("/");
  }, [pageDetail]);

  useEffect(() => {
    setPageInitResponse(null);
    if (pageDetail?.redirectUrl && pageDetail.redirectUrl.trim() !== "") {
      window.parent.postMessage(
        {
          type: MESAGE_TYPE.SUCCESS,
          data: pageDetail,
        },
        "*"
      );
    }
  }, []);

  return (
    <section
      className="payment-page-container"
      style={{ justifyContent: "flex-start", position: "relative" }}
    >
      <main className="main">
        <React.Fragment>
          <div
            className={`top-header ${
              pageDetail?.imageUrl ? "" : "default-top-bg"
            }`}
            style={
              pageDetail?.imageUrl
                ? {
                    backgroundImage: `url(data:image/png;base64,${pageDetail.imageUrl})`,
                    width: "100%", objectFit: "contain" 
                  }
                : {}
            }
          >
            <div
              className={` h-100 ${
                pageDetail?.imageUrl
                  ? "position-bottom-left pl-8 h-100"
                  : "position-center"
              }`}
            >
              <Row justify={"center"} align={"middle"}>
                <div className="merchant-logo-outer">
                  {pageDetail?.merchant?.merchantLogoUrl ? (
                    imageLoadError === "FAILED" ? (
                      <div className="merchant-logo-inner">
                        <span className="text-white font-size-30 font-weight-700">
                          {pageDetail?.merchant?.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    ) : (
                      <img
                        style={{ width: "50px", height: "50px", borderRadius: '50%', objectFit: "contain" }}
                        src={pageDetail?.merchant?.merchantLogoUrl}
                        alt={pageDetail?.merchant?.name}
                        onError={() => {
                          setImageLoadError("FAILED");
                        }}
                      />
                    )
                  ) : (
                    <div className="merchant-logo-inner">
                      <span className="text-white font-size-30 font-weight-700">
                        {pageDetail?.merchant?.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                </div>
                <Typography.Text className='font-size-16 pl-4 font-weight-700 text-white'>
                  {pageDetail?.merchant?.name}
                </Typography.Text>
              </Row>
            </div>
          </div>

          <section className="page-contents  success-wrapper">
            <section className="pt-10" style={{ paddingTop: 20 }}>
              <Row className="mb-3" justify={"center"}>
                <MonnifyIcon type="SUCCESS_ICON"/>
              </Row>
              <Row justify={"center"}>
                <Typography.Title level={5} className="font-size-22 mb-3">
                  Your payment is complete
                </Typography.Title>
              </Row>
              <Row justify={"center"}>
                <Typography.Text className="font-size-15 mb-5 font-weight-400">
                  Your payment has been successfully completed
                </Typography.Text>
              </Row>
            </section>
            <Button
              className="mt-10 mb-15"
              onClick={() => {
                navigate(`/${pageDetail?.code}`);
              }}
              type="primary"
              style={{ maxWidth: 400, width: "80%", margin: "0 auto" }}
            >
              Pay Again
            </Button>
            <div className="mt-5">
              <Row justify={"center"} className="mt-3">
                <Typography.Text className="font-size-12 mb-5 font-weight-400">
                  If you have any questions, contact{" "}
                  <a href={`mailto:${pageDetail?.merchant?.supportEmail}`}>
                    {pageDetail?.merchant?.supportEmail}
                  </a>
                </Typography.Text>
              </Row>
            </div>
          </section>
        </React.Fragment>
      </main>
      <footer className="footer">
        <Row className="mb-5">
          <img
            src="https://app.monnify.com/images/logo-white.svg"
            alt="monnify"
          />
        </Row>
        <Row className="ml-5" align={"middle"}>
          <span className="by-text">by</span>
          <img
            src={"https://app.monnify.com/images/moniepointLogo-white.svg"}
            alt="monnify"
          />
        </Row>
      </footer>
    </section>
  );
};

export default PaymentSuccessful;
