import { Button, Row, Space, Typography } from "antd";
import "./NoMatch.scss";
import notfound from "../../assets/notfound.svg";

const NoMatch = () => {
  return (
    <div className="">
      <div className="not-found-main">
        <div className="not-found-header">
          <Row justify={"center"}>
            <img className={"notfounImage"} src={notfound} alt="not-found" />
          </Row>
        </div>
        <div className={"not-found-content"}>
          <Row justify={"center"}>
            <Typography.Title className="mb-0" level={1}>
              404
            </Typography.Title>
          </Row>
          <Row justify={"center"}>
            <Typography.Title className="mt-0" level={2}>
              Page not found
            </Typography.Title>
          </Row>
          <Row justify={"center"}>
            <Typography.Text
              className="text-grey"
              style={{ textAlign: "center" }}
            >
              This page you are looking for does not exist. It might <br /> have
              been moved or deleted
            </Typography.Text>
          </Row>

          <Row justify={"center"}>
            <div className="mt-10 not-found-action-button">
              <Button
                type="primary"
                onClick={() => window.open("https://monnify.com/")}
              >
                Visit Monnify
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
