import React from "react";
import _ from "lodash";

class MonnifyIcon extends React.Component {
  svgs = {
    SUCCESS_ICON: `<svg width="91" height="90" viewBox="0 0 91 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="45.8112" cy="44.7986" rx="45.0231" ry="44.7986" fill="url(#paint0_linear_27410_296566)"/>
    <g filter="url(#filter0_d_27410_296566)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0591 52.2693L27.0429 40.6424L21.1743 46.4817L35.6594 60.8946C35.748 61.0027 35.8429 61.1073 35.9441 61.208C36.0454 61.3088 36.1505 61.4032 36.2591 61.4913L36.8238 62.0532L36.9329 61.9367C38.5013 62.7655 40.4916 62.5226 41.8127 61.208L70.4442 32.7193L67.7572 30.0456C66.0001 28.2973 63.1512 28.2973 61.3941 30.0456L39.0591 52.2693Z" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_d_27410_296566" x="17.1743" y="28.7344" width="57.27" height="41.6831" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.595833 0 0 0 0 0.348739 0 0 0 1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27410_296566"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27410_296566" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_27410_296566" x1="45.8112" y1="-23.2953" x2="45.8111" y2="72.5737" gradientUnits="userSpaceOnUse">
    <stop stop-color="#D0DB51"/>
    <stop offset="1" stop-color="#17A25F"/>
    </linearGradient>
    </defs>
    </svg>`,
    NOTIFICATION_ICON: `
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22.8728C6.47715 22.8728 2 18.3957 2 12.8728C2 7.34996 6.47715 2.8728 12 2.8728C17.5228 2.8728 22 7.34996 22 12.8728C21.9939 18.3931 17.5203 22.8667 12 22.8728ZM11 15.8728V17.8728H13V15.8728H11ZM11 7.8728V13.8728H13V7.8728H11Z" fill="#00B8C2"/>
    </svg>`,
    TRANSFER_ICON:`
    <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.7983" cy="29.2627" r="25.0185" fill="#00B8C2" fill-opacity="0.1"/>
    <path d="M39.7688 17.5125L15.4219 26.5954C15.1246 26.7049 14.8855 26.9316 14.7604 27.2225C14.6354 27.5134 14.6354 27.8429 14.7604 28.1341C14.8855 28.425 15.1246 28.6517 15.4219 28.7612L25.3044 32.3543L34.8104 23.8601L34.1334 24.7218L26.401 33.4275L30.0016 43.3334C30.0808 43.5572 30.227 43.7512 30.4205 43.8888C30.614 44.0265 30.8453 44.1011 31.0825 44.1027C31.32 44.1011 31.5513 44.0265 31.7448 43.8888C31.9382 43.7512 32.0845 43.5572 32.1637 43.3334L41.25 18.994C41.3535 18.716 41.3463 18.4087 41.2296 18.1361C41.1128 17.8632 40.8955 17.6459 40.6229 17.5291C40.3502 17.4124 40.043 17.4052 39.765 17.509L39.7688 17.5125Z" fill="#00B8C2"/>
    </svg> `,
    COPY_ICON:`
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 12.1693V13.4693C10.0001 13.8141 9.86836 14.1447 9.63394 14.3885C9.39952 14.6323 9.08157 14.7693 8.75005 14.7693H3.75001C3.41849 14.7693 3.10054 14.6323 2.86612 14.3885C2.6317 14.1447 2.5 13.8141 2.5 13.4693V6.31922C2.5 5.97443 2.6317 5.64377 2.86612 5.39997C3.10054 5.15617 3.41849 5.01921 3.75001 5.01921H5.00002M4.99994 3.0693V10.8694C4.99994 11.2141 5.13164 11.5448 5.36606 11.7886C5.60048 12.0324 5.91843 12.1694 6.24995 12.1694H11.25C11.5815 12.1694 11.8995 12.0324 12.1339 11.7886C12.3683 11.5448 12.5 11.2141 12.5 10.8694V5.17661C12.5 5.00343 12.4667 4.83199 12.4021 4.67237C12.3375 4.51275 12.2428 4.36816 12.1237 4.24711L10.0519 2.13979C9.81833 1.90232 9.50471 1.76933 9.1781 1.76929H6.24995C5.91843 1.76929 5.60048 1.90625 5.36606 2.15005C5.13164 2.39385 4.99994 2.72451 4.99994 3.0693Z" stroke="#00B8C2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    NEW_SUCCESS_ICON: `
    <svg width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="70.5" cy="70.5" r="70" fill="#BCE8C0"/>
    <circle cx="70.5001" cy="70.5001" r="55.44" fill="#1EB12D"/>
    <circle cx="70.4999" cy="70.4999" r="43.12" fill="#18902C"/>
    <path d="M49.5075 70.3595C51.0101 68.9016 53.4444 68.8993 54.9489 70.3545L61.3296 76.5251C61.8213 77.0012 62.6174 77.0007 63.1091 76.5238L84.9086 55.358C86.4206 53.8904 88.8728 53.8946 90.3792 55.3671C91.8771 56.8329 91.8733 59.199 90.3688 60.6593L66.3847 83.9452C64.0842 86.1783 60.3555 86.1783 58.0549 83.9452L49.5075 75.6471C48.004 74.1868 48.004 71.8197 49.5075 70.3595Z" fill="white"/>
    </svg>`,
    INFO_ICON:`
    <svg width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="70.5" cy="70.5" r="70" fill="#00B8C2" fill-opacity="0.2"/>
    <circle cx="70.5001" cy="70.5001" r="55.44" fill="#00B8C2" fill-opacity="0.5"/>
    <circle cx="70.4999" cy="70.4999" r="43.12" fill="#00B8C2"/>
    <path d="M63.2197 74.9799V67.1009L75.0582 67.1048L75.0191 82.8201H78.9777V90.66H67.1392V74.9799H63.2197Z" fill="white"/>
    <path d="M67.1392 59.3746V51.46H75.0582V59.3746H67.1392Z" fill="white"/>
    </svg>`
}
render() {
    let { type } = this.props;

    type = _.upperCase(type);
    type = type.replace(/\s+/g, "_");

    let svgHtml = this.svgs[type] || "";

    return (
      <span
        className="monnify-icon"
        style={{
          verticalAlign: "middle",
          display: "inline-block",
          lineHeight: 0,
          ...this.props.style,
        }}
        dangerouslySetInnerHTML={{ __html: svgHtml }}
      />
    );
  }
}

export default MonnifyIcon;
