import { ConfigProvider } from "antd";
import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import NoMatch from "./modules/NoMatch/NoMatch";
import PaymentPageView from "./modules/PaymentPageView/PaymentPageView";
import AuthorizationInstructionPage from "./modules/DirectDebit/AuthorizationInstructionPage";
import AuthorizationNotificationPage from "./modules/DirectDebit/AuthorizationNotificationPage";
import { QueryClient, QueryClientProvider } from "react-query";
import PaymentSuccessful from "./modules/PaymentPageView/PaymentSuccessful";
import "./App.scss";

function App() {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#00b8c2" } }}>
      <QueryClientProvider client={new QueryClient()}>
        <RecoilRoot>
          <Routes>
            <Route path="/payment-success" element={<PaymentSuccessful />} />
            <Route path="/page/not-found" element={<NoMatch />} />
            <Route path="/mandate-auth/:mandateCode/notification" element={<AuthorizationNotificationPage />} />
            <Route path="/mandate-auth/:mandateCode" element={<AuthorizationInstructionPage />} />
            <Route path="/:code" element={<PaymentPageView />} />

            <Route path="*" element={<NoMatch />} />
          </Routes>
        </RecoilRoot>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
