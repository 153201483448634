import React, { Component } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";


class CopyText extends Component {
  state = {
    show: false,
    timeout: null,
  };

  handleCopy = () => {
    let { timeout } = this.state;
    this.setState({ show: true });
    if (timeout != null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      this.setState({ show: false });
    }, 700);
    this.setState({ timeout: timeout });
  };
  render() {
    let { show } = this.state;
    let { value, high = false } = this.props;

    return (
      <StyledCopyText>
        <div className="copy-input">
          <div className={show ? `copied-text show ${high && "high"}` : "copied-text"}>
            Copied
          </div>
          <CopyToClipboard text={value} onCopy={this.handleCopy}>
            {this.props.children}
          </CopyToClipboard>
        </div>
      </StyledCopyText>
    );
  }
}

const StyledCopyText = styled.div`
  .copy-input {
    position: relative;

    .copied-text {
      position: absolute;
      right: 1.2rem;
      padding: 5px 8px;
      font-size: 10px;
      // width: fit-content;
      width: 54px;
      background: #f2f2f2 !important;
      color: #889095;
      font-weight: 700;
      border-radius: 3px;
      opacity: 0;

      margin-left: auto;
      margin-right: auto;
      left: 5;
      right: 0;
      text-align: center;
    }

    .copied-text.show.high {
      animation: pulse-high 150ms forwards;
    }

    .copied-text.show {
      animation: pulse 150ms forwards;
    }
  }

  @keyframes pulse {
    0% {
      transform: translateY(0);
      transform: scale(0.5);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
      transform: translateY(-1.3rem);
      opacity: 1;
    }
  }

  @keyframes pulse-high {
    0% {
      transform: translateY(0);
      transform: scale(0.5);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
      transform: translateY(-2.5rem);
      opacity: 1;
    }
  }
`;

export default CopyText;
