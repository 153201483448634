import { lowerCase, capitalize } from "lodash";
import { notification } from "antd";
import { IconType } from "antd/es/notification/interface";
import moment from "moment";
import { RuleObject } from "antd/es/form";
// import { IconType } from "antd/lib/notification";

const Currency = require("currency.js");

class Util {
  static validateFreeEntryAmount = (rule: any, value: any, callback: any) => {
    const { field } = rule;

    if (!value || value.trim() === "") {
      return callback("Please specify amount");
    } else if (Number(value) < 10) {
      callback("Amount cannot be less than ₦10");
      return;
    }
    callback();
  };
  static validateTextFieldWithCustomValues = (
    rule: any,
    value: any,
    callback: any,
    customValues: string[]
  ) => {
    if (
      value &&
      value.trim() !== "" &&
      !customValues
        .map((item) => item.toLowerCase().trim())
        .includes(value.toLowerCase().trim())
    ) {
      callback('You have entered an incorrect value, please review and try again');
      return;
    }
    callback();
  };

  static disablePastDates = (currentDate: any) => {
    return (
      moment().add(-1, "days") >= currentDate ||
      moment().add(1, "month") <= currentDate
    );
  };

  static isDevelopmentMode = () => {
    return process.env.NODE_ENV === "development";
  };

  static numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static stripCommaFromAmount = (x: string) => {
    return x.replace(/,/g, '')
  }

  static formatAmount = (symbol: string, amount: number) => {
    if (amount === undefined || amount === null) {
      return "₦ -.--";
    }

    if (symbol === "NGN") {
      symbol = "₦";
    } else {
      symbol = symbol || "₦";
    }
    symbol = symbol + "";

    let currencyOption = {
      symbol: symbol,
    };

    let currency = Currency(amount, currencyOption);
    return currency.format(true);
  };

  static getGeneralStatusTagClass = (status: string) => {
    status = status && lowerCase(status);

    switch (status.trim()) {
      case "one time":
      case "single":
        return "one-time";
      case "subscription":
        return "subscription";
      case "success":
      case "paid":
      case "credit":
      case "active":
      case "fixed":
      case "overpaid": {
        return "success";
      }
      case "debit":
      case "failed": {
        return "failed";
      }
      case "underpaid":
      case "invoice":
      case "pending": {
        return "pending";
      }

      case "cancelled":
        return "cancelled";
      default: {
        return "unknown";
      }
    }
  };

  static normalizePaymentStatus = (paymentStatus: string) => {
    paymentStatus = paymentStatus && paymentStatus.replace("_", " ");
    return capitalize(paymentStatus);
  };

  static normalizePaymentMethod = (paymentMethod: string) => {
    paymentMethod = paymentMethod && paymentMethod.replace("_", " ");
    return capitalize(paymentMethod);
  };

  static formHasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  static extractErrors = (error: any) => {
    if (typeof error.response.data.error === "string")
      return [error.response.data.message || error.response.data.error];
    if (error.response) {
      if (error.response.data.error && error.response.data.error.length > 0) {
        return error.response.data.error.map((error: any) => {
          return error.message;
        });
      } else if (error.response.data.message) {
        return [error.response.data.message];
      } else {
        return [error.response.data.error];
      }
    } else {
      return [];
    }
  };

  static showError = (response: any) => {
    let errors = Util.extractErrors(response);
    if (errors && errors.length > 0) {
      // You can put your code here to handle the error
      Util.showMessage("Request Failed", errors[0], "error");
    }
  };

  static showMessage = (
    title: any,
    description: any,
    type: IconType | undefined
  ) => {
    notification.open({
      type: type || "success",
      message: title,
      description: description,
    });
  };

  static isValidEmail = (value: string) => {
    // eslint-disable-next-line no-useless-escape
    // let validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let validator = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,7}$/g;

    return value.match(validator);
  };

  static validateEmail = (rule: any, value: any, callback: any) => {
    if (!value || !Util.isValidEmail(value)) {
      return callback("Please specify a valid email.");
    }

    callback();
  };
}
export default Util;
