import { Col, Row, Typography } from "antd";
import Util from "../../../util/util";
import { useRecoilValue } from "recoil";
import { paymentPageDetail } from "../../../store/store";

const FixedAmountView = () => {
  const pageDetail = useRecoilValue(paymentPageDetail);
  return (
    <Row justify={"space-between"} align={"middle"}>
      <Col>
        <Typography.Text className="text-grey">Amount to pay</Typography.Text>
      </Col>
      <Col>
        <Row align={"middle"}>
          <Typography.Title className="mb-0 mt-0 font-size-24">
            {Util.formatAmount("NGN", pageDetail?.amountPayable || 0)}
          </Typography.Title>
        </Row>
      </Col>
    </Row>
  );
};

export default FixedAmountView;
