import { Row, Spin } from "antd";

const PaymentPageLoading = () => {
  return (
    <>
      <div className="loading-container">
        <Row justify={"center"}>
          <Spin size="large" data-testid='payment-page-loading'/>
        </Row>
      </div>
    </>
  );
};

export default PaymentPageLoading;
