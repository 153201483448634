import React from 'react'
import { Button } from 'antd';
import MonnifyIcon from '../../common/icons';
import { Navigate, useLocation } from "react-router-dom";
import "./AuthorizationNotificationPage.scss";

export default function AuthorizationNotificationPage() {
  const location = useLocation(); 

  const navigateToAuthorizationPage = () => {
    const notificationPageUrl = location.state && location.state.notificationPageUrl;
    if(notificationPageUrl){
       window.location.href = notificationPageUrl;
    }else{
      return <Navigate to={"/page/not-found"} />
    }
  }


  return (
    <div className="mandate-success-page-container">
      <div className="mandate-success-page">
          <MonnifyIcon type="INFO_ICON"/>
          <span className="info-text">
             Once you've completed the mandate authorization instruction you can close this page. You will be notified when your mandate status has changed.
         </span>
          <Button
          className="back-btn"
          onClick={navigateToAuthorizationPage}
        >
          Back to Instruction Page
        </Button>
      </div>
    </div>
  )
}
