import React, { useEffect, useState } from 'react'
import { Button } from 'antd';
import MonnifyIcon from '../../common/icons';
import CopyText from '../../util/copyToClipboard';
import { useNavigate } from "react-router-dom";
import "./AuthorizationInstructionPage.scss";
import Util from '../../util/util';

export default function AuthorizationInstructionPage() {
  const navigate = useNavigate();
  const [customerAccountName, setCustomerAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerAccountName = urlParams.get('customerAccountName');
    const accountNumber = urlParams.get('accountNumber');
    const bankName = urlParams.get('bankName');
    const amount = urlParams.get('amount');

    if (customerAccountName) {
      setCustomerAccountName(customerAccountName);
    }
    if (accountNumber) {
      setAccountNumber(accountNumber);
    }
    if (bankName) {
      setBankName(bankName);
    }
    if (amount) {
      setAmount(amount);
    }
  }, []);

  const navigateToNotificationPage = () => {
    const notificationPageUrl = window.location.href;
    navigate('/mandate-auth/:mandateCode/notification', { replace: true, state: { notificationPageUrl } })
  }

  return (
    <div className="pending-authorization-page-container">
      <div className="pending-authorization-page">
        <div className="title-subtitle-container">
          <span className="title">Pending Authorization</span>
          <span className="subtitle">
          To enable direct debit from your account, please complete a one-time authorization transfer.
          </span>
        </div>
        <div className="transfer-details-container">
          <MonnifyIcon type='TRANSFER_ICON'/>
          <span className="transfer-instruction">
            Make a Transfer of <b>{Util.formatAmount("NGN", amount)}</b> to the account below
          </span>

          <div className="account-details-container">
            <div className="title-value-container">
              <span className="title">Account Number</span>
              <div className='value value-icon'><span style={{marginRight: '4px'}}>{accountNumber}</span>
              <CopyText high value={accountNumber}>
                <Button className='copy-btn'>
                  <MonnifyIcon type='COPY_ICON'/>
                </Button>
                </CopyText>
              </div>
            </div>
            <div className="title-value-container">
              <span className="title">Bank Name</span>
              <span className="value">{bankName}</span>
            </div>
          </div>
          <span className="transfer-note">
          Ensure this transfer is made from <b>{customerAccountName}</b>'s bank account specified in your mandate request.
          </span>
        <Button
          type="primary"
          className='done-btn'
          onClick={navigateToNotificationPage}
        >
         Done
        </Button>
        <span className="footer-note">
        By transferring the above amount you are authorising this mandate
        </span>
        </div>
      </div>
    </div>
  )
}
