import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../assets/logo.svg";
import image from "../../assets/image4.png";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  DatePicker,
} from "antd";
import MultipleChoiceView from "./components/MultipleChoiceView";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  amountOptionSelected,
  dynamicAmountValue,
  paymentPageDetail,
  paymentPageInitResponse,
  paymentPageInitResponseQueryData,
} from "../../store/store";
import FixedAmountView from "./components/FixedAmountView";
import Util from "../../util/util";
import { initializePaymentPageAPI } from "../../api/paymentPage";
import TransactionService from "../../services/transaction-service";
import {
  IPaymentPageCustomAttribute,
  IPaymentPageDetail,
  IPaymentPageInit,
  IPaymentQueryResponse,
} from "@app-models";
import { isTransactionSuccessful } from "../../services/transaction-status";
import { useNavigate, useNavigation } from "react-router-dom";
import DynamicAmountView from "./components/DynamicAmountView";
import moment from "moment";

const PaymentPageLoaded = () => {
  const pageDetail = useRecoilValue(paymentPageDetail);
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [imageLoadError, setImageLoadError] = useState("LOADING");
  const navigate = useNavigate();
  const [, setPageInitResponse] = useRecoilState(paymentPageInitResponse);
  const [queryData, setQueryData] = useRecoilState(
    paymentPageInitResponseQueryData
  );

  const amountSelected = useRecoilValue(amountOptionSelected);

  const dynamicAmount = useRecoilValue(dynamicAmountValue);

  const getOptionAmount = () => {
    if (pageDetail?.amountType === "DYNAMIC") {
      return Number(dynamicAmount);
    }

    if (pageDetail?.amountType === "FIXED") return pageDetail.amountPayable;

    return pageDetail?.amountOptions
      ? pageDetail?.amountOptions[amountSelected as string]?.payableAmount || 0
      : 0;
  };

  const renderAmountType = () => {
    switch (pageDetail?.amountType) {
      case "FIXED":
        return <FixedAmountView />;
      case "FIXED_OPTIONS":
        return <MultipleChoiceView form={form} />;
      case "DYNAMIC":
        return <DynamicAmountView form={form} />;
    }
  };
  const onFinishFailed = (errorInfo: any) => {};
  const onFinish = async (values: any) => {
    try {
      if (isLoading) return;
      const customValues: Array<{ code: any; value: string }> = [];

      Object.keys(pageDetail?.customAttributes || {}).forEach((key) => {
        if (
          typeof values[key] === "object" &&
          values[key].$d &&
          values[key].$d instanceof Date
        ) {
          customValues.push({
            code: key,
            value: values[key].format("YYYY-MM-DD"),
          });
        } else {
          customValues.push({ code: key, value: values[key] });
        }
      });

      setLoading(true);

      const payload: IPaymentPageInit = {
        amount: pageDetail?.amountPayable || "",
        fixedOptionsCode: amountSelected || "",
        contractCode: pageDetail?.contractCode as string,
        currencyCode: "NGN",
        paymentReference: pageDetail?.reference as string,
        customerEmail: values.email,
        customerName: `${values.firstName} ${values.lastName}`,
        customerPhoneNumber: values.phoneNumber && values.phoneNumber,
        paymentMethods: ["CARD", "ACCOUNT_TRANSFER"],
        redirectUrl: window.location.href || pageDetail?.redirectUrl,
        pageAttributes: customValues,
        pageCode: pageDetail?.code as string,
      };

      if (pageDetail?.amountType === "FIXED") delete payload.fixedOptionsCode;
      else {
        payload.amount = getOptionAmount();
      }

      const response = await initializePaymentPageAPI(payload);

      setLoading(false);

      if (response) {
        setPageInitResponse(response);
        setQueryData(response);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const queryTransaction = useCallback(async () => {
    try {
      if (queryData && pageDetail) {
        setLoading(true);
        const response = await TransactionService.queryTransactionStatus(
          queryData.transactionReference,
          queryData.apiKey,
          pageDetail?.config.paymentEngineServiceUrl as string
        );

        setLoading(false);

        const queryResponse = response.data
          .responseBody as IPaymentQueryResponse;

        if (isTransactionSuccessful(queryResponse.paymentStatus)) {
          navigate("/payment-success");
          setQueryData(null);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  }, [pageDetail, queryData]);

  useEffect(() => {
    queryTransaction();
  }, [queryTransaction]);

  const renderValidatedTextFieldInput = (
    elementName: string,
    data: IPaymentPageCustomAttribute
  ) => {
    if (data.validationSubType === "VALIDATED_TEXT_FIELD_LIST") {
      const allowedValues = JSON.parse(
        data.validationValue as string
      ) as string[];
      return (
        <>
        <label>
        <span className="form-label-style">{data.fieldName}</span>
        </label>
        <Form.Item
          name={elementName}
          rules={[
            {
              required: data.mandatory,
              message: "Field is mandatory",
            },
            {
              validator: (rule, value, callback) =>
                Util.validateTextFieldWithCustomValues(
                  rule,
                  value,
                  callback,
                  allowedValues
                ),
            },
          ]}
          key={`${Math.random()}`}
        >
          <Input type={"text"} />
        </Form.Item>
        </>
      );
    }
    if (data.validationSubType === "VALIDATED_TEXT_FIELD_DATASOURCE") {
      const allowedValues = JSON.parse(
        data.validationValue as string
      ) as string[];
      return (
        <>
        <label>
        <span className="form-label-style">{data.fieldName}</span>
        </label>
        <Form.Item
          name={elementName}
          rules={[
            {
              required: data.mandatory,
              message: "Field is mandatory",
            },
            {
              validator: (rule, value, callback) =>
                Util.validateTextFieldWithCustomValues(
                  rule,
                  value,
                  callback,
                  allowedValues
                ),
            },
          ]}
          key={`${Math.random()}`}
        >
          <Input type={"text"} />
        </Form.Item>
        </>
      );
    }
    return null;
  };

  const getSelectOptionList = (values: string | string[]) => {
    if (!values) return [];

    if (typeof values === "string")
      return JSON.parse(values).map((item: any) => ({
        label: item,
        value: item,
      }));
  };

  const renderTextFieldInput = (
    elementName: string,
    data: IPaymentPageCustomAttribute
  ) => {
    const validationValue = data.validationValue
      ? (JSON.parse(data.validationValue as string) as {
          maxLen: string;
          minLen: string;
        })
      : null;
    return (
      <>
      <label>
      <span className="form-label-style">{data.fieldName}</span>
      </label>
      <Form.Item
        name={elementName}
        rules={[
          {
            required: data.mandatory,
            message: "Field is mandatory",
          },
        ]}
        key={`${Math.random()}`}
      >
        <Input
          minLength={validationValue?.minLen ? +validationValue.minLen : 0}
          maxLength={validationValue?.maxLen ? +validationValue.maxLen : 10000}
          type={"text"}
        />
      </Form.Item>
      </>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content={pageDetail?.name as string} />
        <meta property="og:description" content={pageDetail?.description} />
        <meta property="og:image" content={`data:image/png;base64,${pageDetail?.seoImageUrl as string}`} />
        <meta property="og:url" content={`https://paylink.monnify.com/${pageDetail?.code}`}/>
      </Helmet>
      <div
        className={`top-header ${pageDetail?.imageUrl ? "" : "default-top-bg"}`}
        style={
          pageDetail?.imageUrl
            ? {
                backgroundImage: `linear-gradient(180deg, rgba(51, 51, 51, 0.00) 12.65%, rgba(0, 0, 0, 0.6) 70%), url(data:image/png;base64,${pageDetail.imageUrl})`,
              }
            : {}
        }
      >
        <div
          className={` h-100 ${
            pageDetail?.imageUrl
              ? "position-bottom-left pl-8 h-100"
              : "position-center"
          }`}
        >
          <Row justify={"center"} align={"middle"}>
            <div className="merchant-logo-outer">
              {pageDetail?.merchant?.merchantLogoUrl ? (
                imageLoadError === "FAILED" ? (
                  <div className="merchant-logo-inner">
                    <span className="text-white font-size-30 font-weight-700">
                      {pageDetail?.merchant.name.charAt(0).toUpperCase()}
                    </span>
                  </div>
                ) : (
                  <img
                    style={{ width: "50px", height: "50px", borderRadius: '50%', objectFit: "cover" }}
                    src={`data:image/png;base64,${pageDetail?.merchant?.merchantLogoUrl}`}
                    alt={pageDetail?.merchant?.name}
                    onError={() => {
                      setImageLoadError("FAILED");
                    }}
                  />
                )
              ) : (
                <div className="merchant-logo-inner">
                  <span className="text-white font-size-30 font-weight-700">
                    {pageDetail?.merchant?.name.charAt(0).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
            <Typography.Text className='text-shadow font-size-16 pl-4 font-weight-700 text-white'>
              {pageDetail?.merchant?.name}
            </Typography.Text>
          </Row>
        </div>
      </div>
      <section className="info content-body-padding">
        <Typography.Title className="page-title mb-3" level={3}>
          {pageDetail?.name}
        </Typography.Title>
        <Typography.Text className="page-description-content font-size-13">
          {pageDetail?.description}
        </Typography.Text>
      </section>

      <section className="">
        <div className="mt-1">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
            data-testid='payment-page-form'
          >
            <section className="content-body-padding">
              {renderAmountType()}
            </section>

            <section className="page-contents content-body-padding">
              <Typography.Title className="mt-0 font-size-18" level={4}>
                Payment Details
              </Typography.Title>
              <div className="name-container-flex">
              <div className='name-field'>
              <label>
              <span className="form-label-style">First Name</span>
              </label>
              <Form.Item
                name={"firstName"}
                rules={[
                  { required: true, message: "Please provide your first name" },
                ]}
              >
                <Input />
              </Form.Item>
              </div>
         
              <div className='name-field'>
              <label>
              <span className="form-label-style">Last Name</span>
              </label>
              <Form.Item
                name={"lastName"}
                rules={[
                  { required: true, message: "Please provide your last name" },
                ]}
              >
                <Input />
              </Form.Item>
              </div>
              </div>
              {pageDetail?.collectPhoneNumber && (
                <>
               <label>
                 <span className="form-label-style">Phone Number</span>
              </label>  
                <Form.Item
                  style={{marginTop: '4px'}}
                  name={"phoneNumber"}
                  rules={[
                    {
                      required: true,
                      message: "Please provide your phone number",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input addonBefore="+234" placeholder="816312312" data-testid="phoneNumber"/>
                </Form.Item>
                </>
              )}
              <label>
                 <span className="form-label-style">Email</span>
              </label>  
              <Form.Item
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: "Please provide your email",
                    validator: Util.validateEmail,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {Object.keys(pageDetail?.customAttributes || {}).map((key, i) => {
                if (
                  pageDetail?.customAttributes[key].validationType ===
                  "DROPDOWN"
                ) {
                  return (
                    <>
                      <label>
                        <span className="form-label-style">{`Please provide ${pageDetail?.customAttributes[key].fieldName.charAt(0).toLowerCase() + pageDetail?.customAttributes[key].fieldName.slice(1)}`}</span>
                      </label>  
                    <Form.Item
                      name={key}
                      rules={[
                        {
                          required: pageDetail?.customAttributes[key].mandatory,
                          message: `Please provide ${pageDetail?.customAttributes[key].fieldName}`,
                        },
                      ]}
                      key={i}
                    >
                      <Select
                        options={getSelectOptionList(
                          pageDetail?.customAttributes[key].validationValue
                        )}
                        style={{ height: "50px" }}
                        data-testid="custom-dropdown"
                      />
                    </Form.Item>
                    </>
                  );
                }
                if (
                  pageDetail?.customAttributes[key].validationType === "DATE"
                ) {
                  return (
                    <>
                        <label>
                        <span className="form-label-style">{`Please select ${pageDetail?.customAttributes[key].fieldName.charAt(0).toLowerCase() + pageDetail?.customAttributes[key].fieldName.slice(1)}`}</span>
                      </label>  
                    <Form.Item
                      name={key}
                      rules={[
                        {
                          required: pageDetail?.customAttributes[key].mandatory,
                          message: `Please select ${pageDetail?.customAttributes[key].fieldName}`,
                        },
                      ]}
                      key={i}
                    >
                      <DatePicker
                        placeholder=''
                        style={{ height: "50px", width: "100%" }}
                      />
                    </Form.Item>
                    </>
                  );
                }
                if (
                  pageDetail?.customAttributes[key].validationType ===
                  "VALIDATED_TEXT_FIELD"
                ) {
                  return renderValidatedTextFieldInput(
                    key,
                    pageDetail?.customAttributes[key]
                  );
                }
                if (
                  pageDetail?.customAttributes[key].validationType === "TEXT"
                ) {
                  return renderTextFieldInput(
                    key,
                    pageDetail?.customAttributes[key]
                  );
                }
                return (
                  
                  <>
                       <label>
                        <span className="form-label-style">{pageDetail?.customAttributes[key].fieldName}</span>
                      </label>  
                  <Form.Item
                    name={key}
                    rules={[
                      {
                        required: pageDetail?.customAttributes[key].mandatory,
                        message: `Please provide ${pageDetail?.customAttributes[key].fieldName}`,
                      },
                    ]}
                    key={i}
                  >
                    <Input
                      type={
                        pageDetail?.customAttributes[key].validationType ===
                        "FILE_UPLOAD"
                          ? "file"
                          : pageDetail?.customAttributes[key].validationType
                      }
                    />
                  </Form.Item>
                  </>
                );
              })}
              <Form.Item wrapperCol={{ offset: 0 }}>
                <Button
                  className="mt-4"
                  loading={isLoading}
                  htmlType="submit"
                  type="primary"
                  style={{ height: "58px" }}
                >
                  Pay {Util.formatAmount("NGN", getOptionAmount())}
                </Button>
              </Form.Item>
              <div>
                <Row
                  justify={"center"}
                  className="font-size-12 mb-1 font-weight-400 mt-3"
                >
                  <Typography.Text style={{textAlign: 'center'}}>
                    If you have any questions, contact{" "}
                    <a href={`mailto:${pageDetail?.merchant?.supportEmail}`}>
                      {pageDetail?.merchant?.supportEmail}
                    </a>
                  </Typography.Text>
                </Row>
              </div>
            </section>
          </Form>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PaymentPageLoaded;
