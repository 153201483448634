import { Col, Row, Typography, Form, Input, FormInstance } from "antd";
import Util from "../../../util/util";
import { useRecoilState, useRecoilValue } from "recoil";
import { dynamicAmountValue, paymentPageDetail } from "../../../store/store";
import { FC, useEffect } from "react";

interface IProps {
  form?: FormInstance;
}

const DynamicAmountView: FC<IProps> = ({ form }) => {
  const pageDetail = useRecoilValue(paymentPageDetail);
  const [value, setvalue] = useRecoilState(dynamicAmountValue);
  const handleAmountFieldWheel = (event: any) => {
    event.target.blur();
  };

  useEffect(()=>{
    if(form){
      if(value === "0"){
        return;
      }
      form.setFieldsValue({amount: Util.numberWithCommas(Number(value))})
    }
  },[value]) 

  const handleKeyPress = (e: { key: any; preventDefault: () => void; }) => {
    const keyValue = e.key;
    if (!/^[0-9]$/.test(keyValue)) {
      e.preventDefault();
    }
  };

  return (
    <Col>
      <Row>
        <Col>
          <Typography.Text className="form-label-style">Enter Amount</Typography.Text>
        </Col>
      </Row>
      <Row style={{ width: "100%" }} className="free-entry-container">
        <Form.Item
          name={"amount"}
          style={{ width: "100%", marginBottom: '0px' }}
          rules={[{ validator: Util.validateFreeEntryAmount }]}
        >
          <Input
            onChange={(e)=> setvalue(Util.stripCommaFromAmount(e.target.value.replace(/[^0-9,]/g, '')))}
            placeholder="0.00"
            min={10}
            type="text"
            onWheel={handleAmountFieldWheel}
            addonBefore="₦"
            onKeyPress={handleKeyPress}
            style={{height: '58px'}}
          />
        </Form.Item>
      </Row>
    </Col>
  );
};

export default DynamicAmountView;
