import Axios from "axios";
import Util from "../util/util";
import { IPaymentPageInit, IPaymentPageInitResponse } from "@app-models";

Axios.interceptors.response.use(
  (value) => Promise.resolve(value),
  (error) => {
    Util.showError(error);
    Promise.reject(error);
  }
);

export const getPageDetailApi = async (reference: string | undefined) => {
  const response = await Axios.get(`/app/paymentpages/detail/${reference}`);
  return response.data;
};

export const initializePaymentPageAPI = async (payload: IPaymentPageInit) => {
  try {
    const response = await Axios.post("/app/paymentpages/initialize", payload);

    return response.data.data as IPaymentPageInitResponse;
  } catch (error) {}
};
