import {
  Col,
  Divider,
  Form,
  FormInstance,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from "antd";
import Util from "../../../util/util";
import { useRecoilState, useRecoilValue } from "recoil";
import { amountOptionSelected, paymentPageDetail } from "../../../store/store";
import { FC, useEffect, useState } from "react";

interface IProps {
  form?: FormInstance;
}

const MultipleChoiceView: FC<IProps> = ({ form }) => {
  const pageDetail = useRecoilValue(paymentPageDetail);

  const [amountSelected, setAmountSelected] =
    useRecoilState(amountOptionSelected);

  const handleOnChange = (e: RadioChangeEvent) => {
    setAmountSelected(e.target.value);
  };

  const getOptionAmount = () => {
    return (
      pageDetail?.amountOptions[amountSelected as string]?.payableAmount || 0
    );
  };
  return (
    <div>
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <Typography.Text className="text-grey">Amount to pay</Typography.Text>
        </Col>
        <Col>
          <Row align={"middle"}>
            <Typography.Title className="mb-0 mt-0 font-size-24">
              {Util.formatAmount("NGN", getOptionAmount())}
            </Typography.Title>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={5}>Select one option to pay</Typography.Title>
      <div className="mt-10">
        <Form.Item
          style={{marginBottom: '0'}}
          rules={[
            { required: true, message: "Please select one option to pay" },
          ]}
          name={"amountOption"}
        >
          <Radio.Group onChange={(e) => handleOnChange(e)}>
            <Space direction="vertical">
              {Object.keys(pageDetail?.amountOptions || {}).map((key, i) => (
                <Radio key={i} value={key}>
                  <Row
                    className="w-100"
                    justify={"space-between"}
                    align={"middle"}
                  >
                    <span className="ignore-span text-grey2 font-size-16" style={{textTransform: 'capitalize'}}>
                      {pageDetail?.amountOptions[key].optionName}
                    </span>
                    <Typography.Title className="mb-0 mt-0" level={5}>
                      {Util.formatAmount(
                        "NGN",
                        pageDetail?.amountOptions[key].payableAmount || 0
                      )}
                    </Typography.Title>
                  </Row>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>
    </div>
  );
};

export default MultipleChoiceView;
